import React from 'react';
import { RouteGuideDetailsContainer } from 'containers/RouteGuideDetailsContainer';
import { Header } from 'components/Header';
import { SubHeader } from 'components/SubHeader';
import { StyledRouteGuideDetailsPage } from 'styled/RouteGuideDetailsPage';

export const RouteGuideDetailsPage: React.FC = () => {
  return (
    <StyledRouteGuideDetailsPage>
      <Header isShowHomeIcon={true} />
      <div className="content-area">
        <SubHeader />
        <RouteGuideDetailsContainer />
      </div>
    </StyledRouteGuideDetailsPage>
  );
};
