import React from 'react';
import { nextDepartureTime, getRouteDays } from 'common/utils';
import { StyledRouteCard } from 'styled/RouteCard';

type IProps = {
  cardDetails: any;
  onRouteTrack?: (route_id: number) => void;
  isRoutesList?: boolean;
  isRouteTrack?: boolean;
  isRouteGuide?: boolean;
  isRouteGuideDetails?: boolean;
};

export const RouteCard: React.FC<IProps> = (props: Partial<IProps>) => {
  const {
    cardDetails,
    isRoutesList,
    onRouteTrack,
    isRouteTrack,
    isRouteGuide,
    isRouteGuideDetails
  } = props;
  const {
    route_id,
    route_number,
    route_name,
    route_color,
    next_departure,
    route_days
  } = cardDetails;
  const routeDays = route_days ? JSON.parse(route_days) : null;

  const onRouteTrackPage = () => {
    if (!isRoutesList && !isRouteGuide) return;
    onRouteTrack && onRouteTrack(Number(route_id));
  };

  return (
    <StyledRouteCard
      onClick={onRouteTrackPage}
      isClickable={isRoutesList || isRouteGuide}
      cardColor={route_color}
      isRouteTrack={isRouteTrack}
      isRouteGuide={isRouteGuide}
      isRouteGuideDetails={isRouteGuideDetails}
    >
      <div className="RouteCard__Main">
        <div className="RouteCard__row">
          <div className="RouteCard__Column__left">
            <div className="route__number">{route_number}</div>
          </div>
          <div className="RouteCard__Column__right">
            <div className="route__name">{route_name}</div>
            {isRoutesList &&
              (next_departure ? (
                <div className="route__departure">
                  Next Departure
                  <span className="route__time">
                    {nextDepartureTime(next_departure)}
                  </span>
                </div>
              ) : (
                <div className="route__no__departure"></div>
              ))}
            {(isRouteGuide || isRouteGuideDetails) &&
              (routeDays && Object.keys(routeDays)?.length ? (
                <div className="route__departure_days">
                  {getRouteDays(routeDays)}
                </div>
              ) : (
                <div className="route__no__departure"></div>
              ))}
          </div>
        </div>
      </div>
    </StyledRouteCard>
  );
};

RouteCard.defaultProps = {
  cardDetails: {},
  onRouteTrack: () => {},
  isRoutesList: false,
  isRouteTrack: false,
  isRouteGuide: false,
  isRouteGuideDetails: false
};
