export const images = {
  logo: require('assets/images/logo.png'),
  arrownext: require('assets/images/ico_arrownext.svg'),
  iconbus: require('assets/images/ico_bus.svg'),
  iconclock: require('assets/images/ico_clock.svg'),
  iconclose: require('assets/images/ico_close.svg'),
  icondate: require('assets/images/ico_date.svg'),
  iconhome: require('assets/images/ico_home.svg'),
  iconarrowclose: require('assets/images/ico_arrowclose.svg'),
  iconnointernent: require('assets/images/ico_nointernent.svg'),
  iconTrackBus: require('assets/images/icon-track-bus.png')
};
