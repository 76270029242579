import React from 'react';
import { Header } from 'components/Header';
import { SubHeader } from 'components/SubHeader';
import { RouteGuideListContainer } from 'containers/RouteGuideListContainer';
import styled from 'styled-components';

export const StyledRouteGuideListPage = styled.div`
  .route-guide-content-area {
    padding: 19px 0 0 0;
  }
`;

export const RouteGuideListPage: React.FC = () => {
  return (
    <StyledRouteGuideListPage>
      <Header isShowHomeIcon={true} />
      <div className="route-guide-content-area">
        <SubHeader />
        <RouteGuideListContainer />
      </div>
    </StyledRouteGuideListPage>
  );
};
