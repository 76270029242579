import styled, { css } from 'styled-components';

export type IStyledRoutesCLProps = {
  isLoading?: boolean;
  isNoRoutes?: boolean;
};

export const StyledRoutesListContainer = styled.div<IStyledRoutesCLProps>`
  .overflow__hidden {
    overflow: hidden;
    .RoutesListContainer__block {
      overflow: hidden;
      overflow-y: auto;
      max-height: calc(100vh - 350px);
      margin-right: 0;
      padding-right: 0;
      ${({ isLoading, isNoRoutes }) =>
        (isLoading || (!isNoRoutes && !isLoading)) &&
        css`
          mask-image: linear-gradient(
            to bottom,
            transparent 0%,
            rgb(255, 255, 255) 0%,
            rgb(255, 255, 255) 80%,
            transparent 100%
          );
          mask-size: cover;
          -webkit-mask-image: linear-gradient(
            to bottom,
            transparent 0%,
            rgb(255, 255, 255) 0%,
            rgb(255, 255, 255) 80%,
            transparent 100%
          );
          -webkit-mask-size: cover;
        `}
    }
    .RoutesListContainer__block::-webkit-scrollbar {
      width: 0px;
    }
    .RoutesListContainer__block::-webkit-scrollbar-thumb {
      width: 0px;
    }
  }

  @media screen and (max-width: 360px) {
    .overflow__hidden {
      .RoutesListContainer__block {
        max-height: calc(100vh - 365px);
      }
    }
  }
`;
