import React from 'react';
import { StyledNoRunsFound } from 'styled/NoRunsFound';

export const NoRunsFound: React.FC = () => {
  return (
    <StyledNoRunsFound>
      <div className="no__runs_message">No runs found</div>
    </StyledNoRunsFound>
  );
};
