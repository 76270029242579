import styled from 'styled-components';
import { images } from 'config/images';

export const StyledSubHeader = styled.div`
  .SubHeader__row {
    padding: 0 19px 18px;
    display: flex;
    align-items: center;
  }
  .SubHeader__column {
    display: flex;
    align-items: center;
    padding-right: 14px;
    .icoview {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 12px;
      flex: 0 0 12px;
      &.time {
        background: url('${images.iconclock}') no-repeat center center;
        background-size: 12px;
      }
      &.date {
        background: url('${images.icondate}') no-repeat center center;
        background-size: 12px;
      }
    }
    .text {
      font-size: 14px;
      line-height: 16px;
      color: #454545;
      padding-left: 7px;
      white-space:nowrap;
    }
  }
  .SubHeader__subtext__row {
      font-size: 14px;
      line-height: 16px;
      color: #454545;
      padding: 0 19px 18px;
  }
`;
