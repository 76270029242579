import styled from 'styled-components';

export const StyledMapRouteRunCard = styled.div`
  top: 215px;
  position: absolute;
  .MapRouterunCard__Main {
    padding: 9px 19px;
  }
  .MapRouterunCard__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 6px;
    padding: 9px 18px;
    max-width: 260px;
    width: 100%;
    margin-bottom: 15px;
    cursor: pointer;
    &.selected-route-run {
      background: rgba(0, 0, 0, 0.8);
      cursor: default;
    }
    .MapRouterunCard__time__col {
      font-size: 16px;
      line-height: 19px;
      color: #fff;
      padding-right: 15px;
      white-space: nowrap;
    }
    .MapRouterunCard__route__col {
      font-size: 16px;
      line-height: 19px;
      color: #fff;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;
