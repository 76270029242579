import React, { useEffect } from 'react';

type IProps = {
  lat: number;
  lng: number;
  icon?: any;
  map: any;
  maps: any;
};

export const Marker: React.FC<IProps> = (props: Partial<IProps>) => {
  let { lat, lng, icon, map, maps } = props;
  //@ts-ignore
  lat = +lat;
  //@ts-ignore
  lng = +lng;
  //console.log({ lat, lng });

  useEffect(() => {
    const { Marker } = maps;

    const markerIcon = new Marker({
      position: { lat, lng },
      icon: icon,
      zIndex: 1001
    });

    markerIcon.setMap(map);

    return () => {
      markerIcon.setMap(null);
    };
  }, [lat, lng, icon, map, maps]);

  return null;
};
