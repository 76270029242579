import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { App } from './App';
import { GlobalStyles } from 'styled/globalStyles';
import { toast } from 'components/Toast';
import { isDevelopment, SENTRY_DSN } from 'config/env';
import * as serviceWorker from './serviceWorker';
import './index.css';

toast.configure({ hideProgressBar: true, autoClose: 3000 });

if (!isDevelopment) {
  Sentry.init({
    dsn: SENTRY_DSN
  });
}

ReactDOM.render(
  <>
    <App /> <GlobalStyles />
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
