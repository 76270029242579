import React from 'react';
import { IAlert } from 'types/index';
import { generalAlertTime } from 'common/utils';
import Popup from 'reactjs-popup';
import {
  StyledGeneralAlertList,
  StyledGeneralAlertListPopup
} from 'styled/GeneralAlertList';

type IProps = {
  isShowAllAlerts: boolean;
  alertsList: IAlert[];
  onHandleShowAllAlerts: (value: boolean) => void;
};

export const GeneralAlertList: React.FC<IProps> = (props: Partial<IProps>) => {
  const { isShowAllAlerts, alertsList = [], onHandleShowAllAlerts } = props;
  const onClose = () => {
    onHandleShowAllAlerts && onHandleShowAllAlerts(false);
  };

  return (
    <StyledGeneralAlertList>
      <Popup
        open={isShowAllAlerts}
        modal
        closeOnDocumentClick={true}
        closeOnEscape={true}
        lockScroll={true}
        contentStyle={StyledGeneralAlertListPopup}
        onClose={onClose}
      >
        <div className="GeneralAlertList__Main">
          <div className="GeneralAlertList__row">
            <div className="GeneralAlertList__close__icon" onClick={onClose} />
            <div className="GeneralAlertList__title">Alerts</div>
            <div className="GeneralAlertList__column__list__main">
              {alertsList.map(alert => {
                return (
                  <div
                    className="GeneralAlertList__column__listfull"
                    key={alert.alert_id}
                  >
                    <div className="GeneralAlertList__timetag">
                      {alert.alert_datetime
                        ? generalAlertTime(alert.alert_datetime)
                        : ''}
                    </div>
                    <div className="GeneralAlertList__des">
                      {alert.alert_message ? alert.alert_message : ''}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Popup>
    </StyledGeneralAlertList>
  );
};

GeneralAlertList.defaultProps = {
  isShowAllAlerts: false,
  alertsList: [],
  onHandleShowAllAlerts: () => {}
};
