import styled from 'styled-components';

export type IStyledDeparturesProps = {
  isToggle?: boolean;
};

export const StyledDeparturesList = styled.div<IStyledDeparturesProps>`
  .DeparturesList__Main {
    padding: 0;
    position: relative;
    .DeparturesList__openclose__icon {
      width: 45px;
      height: 27px;
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 8px;
      max-width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: fixed;
      top: ${({ isToggle }) => (isToggle ? 'auto' : 'auto')};
      bottom: ${({ isToggle }) => (isToggle ? '280px' : '0')};
      right: 8px;
      transition: all 0.4s ease-in-out;
      img {
        &.rotate {
          -ms-transform: rotate(180deg); /* IE 9 */
          -webkit-transform: rotate(180deg); /* Safari 3-8 */
          transform: rotate(180deg);
        }
      }
    }

    .DeparturesList__whitebox {
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      border-radius: 0;
      z-index: 1;
      position: relative;
      overflow: hidden;
      overflow-y: auto;
      max-height: 280px;
      &.hide {
        max-height: 0;
        opacity: 0;
        -moz-transition: all 0.4s;
        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
        padding: 0px;
      }
      &.show {
        opacity: 1;
        -moz-transition: all 0.4s;
        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
        padding: 12px 24px;
      }
    }
  }

  .DeparturesList__title {
    font-size: 14px;
    line-height: 16px;
    color: #454545;
    font-weight: bold;
    width: 100%;
  }

  .DeparturesList__column__listfull {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 12px 0;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
    &:last-child {
      border: none;
    }
    .DeparturesList__weekday__name {
      font-size: 12px;
      line-height: 14px;
      color: #7b7b7b;
      font-weight: bold;
      padding-bottom: 10px;
    }
    .DeparturesList__empty {
      font-size: 12px;
      line-height: 14px;
      color: #7b7b7b;
      font-style: italic;
      padding-bottom: 4px;
      font-weight: 500;
    }
    .DeparturesList__timetag__block {
      display: grid;
      grid-column-gap: 20px;
      grid-row-gap: 14px;
      grid-template-columns: repeat(8, 1fr);
      .DeparturesList__timetag {
        background: #1f8eed;
        padding: 7px 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 14px;
        color: #fff;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        border-radius: 40px;
        text-transform: lowercase;
        white-space: nowrap;
        cursor: default;
      }
    }
  }

  .DeparturesList__whitebox::-webkit-scrollbar {
    width: 0px;
  }
  .DeparturesList__whitebox::-webkit-scrollbar-thumb {
    width: 0px;
  }

  @media screen and (max-width: 767px) {
    .DeparturesList__column__listfull {
      .DeparturesList__timetag__block {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }

  @media screen and (max-width: 480px) {
    .DeparturesList__column__listfull {
      .DeparturesList__timetag__block {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
  @media screen and (max-width: 414px) {
    .DeparturesList__Main {
      .DeparturesList__openclose__icon {
        /* top: -48px; */
      }
    }
    .DeparturesList__column__listfull {
      .DeparturesList__timetag__block {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
`;
