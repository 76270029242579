import React, { useEffect } from 'react';
import { IRouteMapConverted } from 'types/index';
import { arrayOfArraysIntoArrayOfObjects } from 'common/utils';

type IProps = {
  route_map_converted: IRouteMapConverted | any;
  map: any;
  maps: any;
};

export const Polyline: React.FC<IProps> = (props: Partial<IProps>) => {
  const { map, maps, route_map_converted } = props;

  useEffect(() => {
    const { Polyline, Marker, LatLngBounds } = maps;
    const pathLocations = arrayOfArraysIntoArrayOfObjects(
      JSON.parse(route_map_converted)
    );

    const line = new Polyline({
      path: pathLocations,
      geodesic: true,
      strokeColor: '#5cb85c',
      strokeOpacity: 1,
      strokeWeight: 5
    });

    line.setMap(map);

    const setPolylineCenter = (line: any) => {
      const bounds = new LatLngBounds();
      const points = line.getPath().getArray();
      for (let n = 0; n < points.length; n++) {
        bounds.extend(points[n]);
      }
      map.fitBounds(bounds);
    };

    setPolylineCenter(line);

    const origin = pathLocations.shift();
    const destination = pathLocations.pop();

    const markerOrigin = new Marker({
      position: origin,
      title: 'Source Origin',
      label: {
        color: 'white',
        fontWeight: 'bold',
        text: 'A'
      },
      zIndex: 1001
    });

    const markerDestination = new Marker({
      position: destination,
      title: 'Source Destination',
      label: {
        color: 'white',
        fontWeight: 'bold',
        text: 'B'
      },
      zIndex: 1000
    });

    markerOrigin.setMap(map);
    markerDestination.setMap(map);

    return () => {
      line.setMap(null);
      markerOrigin.setMap(null);
      markerDestination.setMap(null);
    };
  }, [route_map_converted, map, maps]);

  return null;
};
