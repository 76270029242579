import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { GeneralAlertCardSkeleton } from 'styled/GeneralAlertCardSkeleton';

export const GeneralAlertsSkeleton: React.FC = () => {
  return (
    <SkeletonTheme color="#eee" highlightColor="white">
      <GeneralAlertCardSkeleton>
        <div className="GeneralAlertCardSkeleton__Main">
          <div className="GeneralAlertCardSkeleton__row">
            <div className="GeneralAlertCardSkeleton__Column__left">
              <div className="GeneralAlertCardSkeleton__title">
                <Skeleton height={20} width={150} />
              </div>
              <Skeleton height={14} width={240} />
            </div>
            <Skeleton height={20} width={70} />
          </div>
        </div>
      </GeneralAlertCardSkeleton>
    </SkeletonTheme>
  );
};
