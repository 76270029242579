import styled, { css } from 'styled-components';

export type IStyledRouteCardProps = {
  cardColor?: string;
  isClickable?: boolean;
  isRouteTrack?: boolean;
  isRouteGuide?: boolean;
  isRouteGuideDetails?: boolean;
};

export const StyledRouteCard = styled.div<IStyledRouteCardProps>`
  .RouteCard__Main {
    padding: 0 19px 19px;
  }
  .RouteCard__row {
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    padding: ${({ isRouteTrack, isRouteGuide, isRouteGuideDetails }) =>
      isRouteTrack
        ? '10px 15px 10px 30px'
        : isRouteGuide
        ? '12px 12px 24px 30px'
        : isRouteGuideDetails
        ? '10px 15px 10px 30px'
        : '12px 12px 24px 30px'};
    display: flex;
    align-items: center;
    border-radius: 6px 0px 0px 6px;
    border-left: 9px solid;
    border-color: ${({ cardColor }) => (cardColor ? cardColor : '#000000')};
    ${props =>
      props.isClickable &&
      css`
        cursor: pointer;
      `}
  }
  .RouteCard__Column__left {
    padding-right: 22px;
    .route__number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      flex: 0 0 50px;
      background: #f1f1f1;
      color: #fff;
      font-size: 20px;
      line-height: 23px;
      border-radius: 50%;
      background: ${({ cardColor }) => (cardColor ? cardColor : '#000000')};
    }
  }
  .RouteCard__Column__right {
    .route__name {
      font-size: 20px;
      line-height: 23px;
      color: #000000;
      padding-bottom: 4px;
    }
    .route__departure {
      font-size: 14px;
      line-height: 16px;
      color: #777777;
      .route__time {
        padding-left: 8px;
        font-weight: bold;
      }
    }
    .route__departure_days {
      font-size: 12px;
      line-height: 14px;
      color: #878787;
    }
    .route__no__departure {
      font-size: 12px;
      line-height: 14px;
      color: #7b7b7b;
      font-style: italic;
      padding-bottom: 4px;
      font-weight: 500;
    }
  }

  @media screen and (max-width: 360px) {
    .RouteCard__row {
      padding: 12px 15px 20px;
    }
    .RouteCard__Column__left {
      padding-right: 15px;
    }
    .RouteCard__Column__right {
      .route__name {
        font-size: 18px;
      }
      .route__departure_days {
        font-size: 11px;
        line-height: 13px;
      }
    }
  }
`;
