import { createBrowserHistory } from 'history';

import { routes as routes_config, IRoutesConfig } from 'config/routes_config';
import { RouteListPage } from 'pages/RouteListPage';
import { RouteTrackerPage } from 'pages/RouteTrackerPage';
import { RouteGuideListPage } from 'pages/RouteGuideListPage';
import { RouteGuideDetailsPage } from 'pages/RouteGuideDetailsPage';

export const history = createBrowserHistory();

export const routes: IRoutesConfig = {
  [routes_config.index.id]: {
    ...routes_config.index,
    component: RouteListPage
  },
  [routes_config.route_tracker.id]: {
    ...routes_config.route_tracker,
    component: RouteTrackerPage
  },
  [routes_config.routes_guide_list.id]: {
    ...routes_config.routes_guide_list,
    component: RouteGuideListPage
  },
  [routes_config.route_guide_details.id]: {
    ...routes_config.route_guide_details,
    component: RouteGuideDetailsPage
  }
};
