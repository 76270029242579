import React, { Fragment, useContext } from 'react';
import { observer } from 'mobx-react';
import { useMount, useUnmount } from 'react-use';
import { rootContext } from 'stores';
import { Router, Route, Switch } from 'react-router-dom';
import { history, routes } from 'config/routes';
import { HSQHomeScreen } from 'components/HSQHomeScreen';
import { NoInternent } from 'components/NoInternent';
import { IAlert } from 'types';
import { subscribeForAlerts } from 'services/pusher';
import { toast } from 'components/Toast';
import { RealTimeAlert } from 'components/RealTimeAlert';
import { NewUpdateAlert } from 'components/NewUpdateAlert';
import { CloseButton } from 'components/CloseButton';

export const App: React.FC = observer(props => {
  const {
    AppStore: {
      checkInternetConnection,
      state: {
        ui: { isInternetConnection }
      }
    },
    AlertsStore: { onSubscribePusherAlerts }
  } = useContext(rootContext);

  useMount(() => {
    console.log('app mounted');
    checkInternetConnection();
    window.addEventListener('online', checkInternetConnection);
    window.addEventListener('offline', checkInternetConnection);

    subscribeForAlerts((data: IAlert) => {
      toast(<RealTimeAlert data={data} />, {
        position: 'top-center',
        autoClose: 5000,
        className: 'real-time-alert-toast',
        closeButton: <CloseButton />
      });
      onSubscribePusherAlerts(data);
    });
    window.addEventListener('newContentAvailable', () => {
      toast(<NewUpdateAlert />, {
        position: 'top-center',
        autoClose: false,
        className: 'real-time-alert-toast',
        closeButton: <CloseButton />
      });
    });
  });

  useUnmount(() => {
    window.removeEventListener('online', checkInternetConnection);
    window.removeEventListener('offline', checkInternetConnection);
    window.removeEventListener('newContentAvailable', () => {});
  });

  if (!isInternetConnection) {
    return <NoInternent />;
  }

  return (
    <Fragment>
      <Router history={history}>
        <Switch>
          {Object.keys(routes).map(key => {
            const { component: Component, exact, path, id } = routes[key];
            return (
              <Route exact={exact} path={path} key={id} component={Component} />
            );
          })}
        </Switch>
      </Router>
      <HSQHomeScreen />
    </Fragment>
  );
});
