import { observable, action } from 'mobx';
import { getAlertsApi } from 'services/rider';
import { IAlert } from 'types/index';
import { RootStore } from 'stores';
import { getApiErrorMessage } from 'services/utils';

export type IInitialState = {
  data: {
    alertsList: IAlert[] | null;
    totalAlerts: number | null;
    alertsErrorMessage: string | null;
  };
  ui: {
    isAlertsListLoading: boolean;
    isShowAllAlerts: boolean;
  };
};

export const initialState: IInitialState = {
  data: {
    alertsList: null,
    totalAlerts: null,
    alertsErrorMessage: null
  },
  ui: {
    isAlertsListLoading: false,
    isShowAllAlerts: false
  }
};

export class AlertsStore {
  @observable state: IInitialState = initialState;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    /* use this space to hydrate state from localStorage */
  }

  onGetAlertsList = async () => {
    const { ui, data } = this.state;
    this.setState({
      ui: { ...ui, isAlertsListLoading: true }
    });
    try {
      const response = await getAlertsApi();
      this.setState({
        data: {
          alertsErrorMessage: null,
          alertsList: response?.data?.alerts.reverse() || null,
          totalAlerts: response?.data?.total || null
        },
        ui: {
          ...ui,
          isAlertsListLoading: false
        }
      });
    } catch (error) {
      const message = getApiErrorMessage(error);
      this.setState({
        data: {
          ...data,
          alertsErrorMessage: message
        },
        ui: {
          ...ui,
          isAlertsListLoading: false
        }
      });
    }
  };

  onHandleShowAllAlerts = (value: boolean) => {
    const { ui } = this.state;
    this.setState({
      ui: {
        ...ui,
        isShowAllAlerts: value
      }
    });
  };

  onSubscribePusherAlerts = (alert: IAlert) => {
    const { data } = this.state;
    let { alertsList } = data;

    //@ts-ignore
    if (alertsList?.length) {
      //@ts-ignore
      alertsList?.unshift(alert);
    } else {
      alertsList = [alert];
    }

    this.setState({
      data: {
        ...data,
        alertsList: alertsList
      }
    });
  };

  @action
  setState = (params: Partial<IInitialState>) => {
    const { state } = this;
    this.state = {
      ...state,
      ...params
    };
    /* use this space to save/sync state with localStorage */
  };

  @action
  reset = () => {
    this.state = initialState;
    /* use this space to reset localStorage in case if you're persisting this state */
  };
}
