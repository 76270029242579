import styled from 'styled-components';

export type IStyledCardSkeletonProps = {
  isRouteTrack?: boolean;
  isRouteGuide?: boolean;
  isRouteGuideDetails?: boolean;
};

export const StyledRouteCardSkeleton = styled.div<IStyledCardSkeletonProps>`
  .RouteCardSkeleton__Main {
    padding: 0 19px 18px;
  }
  .RouteCardSkeleton__row {
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    padding: ${({ isRouteTrack, isRouteGuide, isRouteGuideDetails }) =>
      isRouteTrack
        ? '10px 15px 10px 30px'
        : isRouteGuide
        ? '10px 15px 10px 30px'
        : isRouteGuideDetails
        ? '10px 15px 10px 30px'
        : '12px 12px 24px 30px'};
    display: flex;
    align-items: center;
    border-radius: 6px 0px 0px 6px;
    border-left: 9px solid;
    border-color: rgb(238, 238, 238);
  }
  .RouteCardSkeleton__Column__left {
    padding-right: 22px;
  }
  .RouteCardSkeleton__Column__right {
    .route_skeleton__note {
      padding-top: 4px;
    }
  }

  @media screen and (max-width: 360px) {
    .RouteCardSkeleton__row {
      padding: 12px 15px 20px;
    }
    .RouteCardSkeleton__Column__left {
      padding-right: 15px;
    }
    .RouteCardSkeleton__Column__right {
      .route__name {
        font-size: 18px;
      }
    }
  }
`;
