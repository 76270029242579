import styled from 'styled-components';

export const StyledNoRunsFound = styled.div`
  padding: 20px;
  .no__runs_message {
    font-size: 25px;
    line-height: 28px;
    color: #7b7b7b;
    font-style: italic;
    padding-bottom: 4px;
    font-weight: 500;
    text-align: center;
  }
`;
