import styled from 'styled-components';

export const StyledRouteGuideDetailsContainer = styled.div`
  .RouteGuideDetailsContainer__Main {
    position: relative;
    .RouteGuideDetailsContainer__DeparturesList {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      max-width: 100%;
      margin: 0 auto;
      bottom: 0;
    }
  }
`;
