import React, { useContext, Fragment, useState } from 'react';
import { useMount, useUnmount } from 'react-use';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { rootContext } from 'stores';
import { RouteCard } from 'components/RouteCard';
import { NoRouteFound } from 'components/NoRouteFound';
import { RouteCardSkeleton } from 'components/RouteCardSkeleton';
import { MapRouteCard } from 'components/MapRouteRunCard';
import { NoRunsFound } from 'components/NoRunsFound';
import { MapNotificationAlert } from 'components/MapNotificationAlert';
import { GeneralAlertList } from 'components/GeneralAlertList';
import { Map } from 'components/Map';
import { getDisplayDateTime, constantValue } from 'common/utils';

export const RouteTrackerContainer: React.FC = observer(() => {
  const [dateTime, setDateTime] = useState(() => {
    return getDisplayDateTime();
  });

  const [isStopTime] = useState(constantValue.stopRouteTime);
  const [isStartTime] = useState(constantValue.startRouteTime);

  const { timeH } = dateTime;
  const currentTime = parseInt(timeH);
  const { route } = useParams();
  const {
    RouteDetailsStore: {
      state,
      onGetRouteDetails,
      onChangeRouteRun,
      unSubscribePusherForLiveLocation
    },
    AlertsStore: { state: alertsState, onGetAlertsList, onHandleShowAllAlerts }
  } = useContext(rootContext);
  const {
    ui: { isRouteDetailsLoading },
    data: { routeDetails, selectedRouteRun, routeDetailsErrorMessage }
  } = state;
  const {
    ui: { isShowAllAlerts },
    data: { alertsList }
  } = alertsState;

  useMount(() => {
    onGetRouteDetails({ route_id: Number(route) });
    onGetAlertsList();
  });

  useUnmount(() => {
    unSubscribePusherForLiveLocation();
  });
  console.log(
    'isStartTime <= currentTime && isStopTime >= currentTime',
    isStopTime >= currentTime
  );
  return (
    <div className="RouteTrackerContainer__Main">
      {isRouteDetailsLoading ? (
        <RouteCardSkeleton isRouteTrack={true} />
      ) : routeDetails && Object.keys(routeDetails).length ? (
        <Fragment>
          <RouteCard cardDetails={routeDetails} isRouteTrack={true} />
          {routeDetails?.runs?.length ? (
            <Fragment>
              <Map selectedRouteRun={selectedRouteRun} isLiveTrack={true} />
              {isStartTime <= currentTime && isStopTime >= currentTime && (
                <MapRouteCard
                  selectedRouteRun={selectedRouteRun}
                  runs={routeDetails.runs}
                  onChangeRouteRun={onChangeRouteRun}
                />
              )}
              {alertsList?.length && (
                <Fragment>
                  <MapNotificationAlert
                    alertsList={alertsList.slice(0, 5)}
                    onHandleShowAllAlerts={onHandleShowAllAlerts}
                  />
                  <GeneralAlertList
                    isShowAllAlerts={isShowAllAlerts}
                    alertsList={alertsList.slice(0, 5)}
                    onHandleShowAllAlerts={onHandleShowAllAlerts}
                  />
                </Fragment>
              )}
            </Fragment>
          ) : (
            <NoRunsFound />
          )}
        </Fragment>
      ) : (
        <NoRouteFound isRouteTrack={true} message={routeDetailsErrorMessage} />
      )}
    </div>
  );
});
