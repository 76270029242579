import React, { useState, Fragment, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { IRun, IGuideRun } from 'types/index';
import { GOOGLE_MAP_KEY } from 'config/env';
import { Marker } from 'components/Marker';
import { Polyline } from 'components/Polyline';
import { StyledGoogleMap } from 'styled/GoogleMap';
import { images } from 'config/images';

type IProps = {
  selectedRouteRun: IRun | IGuideRun | any;
  isLiveTrack?: boolean;
};

export const Map: React.FC<IProps> = (props: Partial<IProps>) => {
  const [state, setState] = useState({
    map: null,
    maps: null,
    mapLoaded: false
  });
  const [center, setCenter] = useState({ lng: 0, lat: 0 });
  const { map, maps, mapLoaded } = state;
  const { selectedRouteRun, isLiveTrack } = props;
  const { route_map_converted, run_lat, run_lng } = selectedRouteRun;

  useEffect(() => {
    if (!route_map_converted) return;
    const [data] = JSON.parse(route_map_converted);
    setCenter({ lng: data?.[0] ?? 0, lat: data?.[1] ?? 0 });

    return () => {
      setCenter({ lng: 0, lat: 0 });
    };
  }, [route_map_converted]);

  //console.log({ run_lat, run_lng });

  return (
    <StyledGoogleMap>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAP_KEY, language: 'en' }}
        defaultZoom={15}
        center={center}
        options={{ fullscreenControl: false, zoomControl: false }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          setState({ map: map, maps: maps, mapLoaded: true });
        }}
        shouldUnregisterMapOnUnmount={true}
      >
        {mapLoaded && route_map_converted && (
          <Fragment>
            <Polyline
              map={map}
              maps={maps}
              route_map_converted={route_map_converted}
            />
            {isLiveTrack && run_lat && run_lng && (
              <Marker
                map={map}
                maps={maps}
                lat={run_lat}
                lng={run_lng}
                icon={images.iconTrackBus}
              />
            )}
          </Fragment>
        )}
      </GoogleMapReact>
    </StyledGoogleMap>
  );
};

Map.defaultProps = {
  selectedRouteRun: {},
  isLiveTrack: false
};
