import React, { useState } from 'react';
import classNames from 'classnames';
import { IRouteDepartureDays } from 'types/index';
import { nextDepartureTime, departuresDayKeys } from 'common/utils';
import { images } from 'config/images';
import { StyledDeparturesList } from 'styled/DeparturesList';

type IProps = {
  route_days: string | IRouteDepartureDays | any;
};

export const DeparturesList: React.FC<IProps> = (props: IProps) => {
  const [isToggle, setToggle] = useState(true);
  const { route_days } = props;
  const routeDays = route_days ? JSON.parse(route_days) : null;

  return (
    <StyledDeparturesList isToggle={isToggle}>
      <div className="DeparturesList__Main">
        <div
          className="DeparturesList__openclose__icon"
          onClick={() => setToggle(!isToggle)}
        >
          <img
            src={images.iconarrowclose}
            alt="arrow"
            className={classNames({
              rotate: isToggle
            })}
          />
        </div>
        <div
          className={classNames('DeparturesList__whitebox', {
            show: isToggle,
            hide: !isToggle
          })}
        >
          <div className="DeparturesList__title">Departures</div>
          {departuresDayKeys.map((day: string, index: number) => {
            const departureTimes: string[] = routeDays?.[index + 1];
            return (
              <div className="DeparturesList__column__listfull" key={day}>
                <div className="DeparturesList__weekday__name">{day}</div>
                <div className="DeparturesList__timetag__block">
                  {routeDays ? (
                    departureTimes?.length ? (
                      departureTimes.map((time: string) => (
                        <div className="DeparturesList__timetag" key={time}>
                          {nextDepartureTime(time)}
                        </div>
                      ))
                    ) : (
                      <div className="DeparturesList__empty">No departures</div>
                    )
                  ) : (
                    <div className="DeparturesList__empty">No departures</div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </StyledDeparturesList>
  );
};
