import { observable, action } from 'mobx';
import { getGuideRoutesApi } from 'services/rider';
import { RootStore } from 'stores';
import { history } from 'config/routes';
import { IGuideRoute } from 'types/index';
import { routes as routes_config } from 'config/routes_config';

export type IInitialState = {
  data: {
    routeGuideList: IGuideRoute[] | null;
    totalRouteGuide: number | null;
  };
  ui: {
    isRouteGuideListLoading: boolean;
  };
};

export const initialState: IInitialState = {
  data: {
    routeGuideList: null,
    totalRouteGuide: null
  },
  ui: {
    isRouteGuideListLoading: false
  }
};

export class RouteGuideStore {
  @observable state: IInitialState = initialState;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    /* use this space to hydrate state from localStorage */
  }

  onGetRoutesGuideList = async () => {
    this.setState({
      ui: { isRouteGuideListLoading: true }
    });
    try {
      const response = await getGuideRoutesApi();
      this.setState({
        data: {
          routeGuideList: response?.data?.routes || null,
          totalRouteGuide: response?.data?.total || null
        },
        ui: {
          isRouteGuideListLoading: false
        }
      });
    } catch (error) {
      this.setState({
        ui: {
          isRouteGuideListLoading: false
        }
      });
    }
  };

  onRouteGuideSelect = ({ route_id }: { route_id: number }) => {
    history.push(routes_config.route_guide_details.path_string({ route_id }));
  };

  @action
  setState = (params: Partial<IInitialState>) => {
    const { state } = this;
    this.state = {
      ...state,
      ...params
    };
    /* use this space to save/sync state with localStorage */
  };

  @action
  reset = () => {
    this.state = initialState;
    /* use this space to reset localStorage in case if you're persisting this state */
  };
}
