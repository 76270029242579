import React from 'react';
import { StyledNoRouteFound } from 'styled/NoRouteFound';

type IProps = {
  isRoutesList?: boolean;
  isRouteTrack?: boolean;
  isRouteGuide?: boolean;
  isRouteGuideDetails?: boolean;
  message?: string | null;
};

export const NoRouteFound: React.FC<IProps> = (props: Partial<IProps>) => {
  const {
    isRoutesList,
    isRouteTrack,
    isRouteGuide,
    isRouteGuideDetails,
    message
  } = props;

  return (
    <StyledNoRouteFound
      isRoutesList={isRoutesList}
      isRouteTrack={isRouteTrack}
      isRouteGuide={isRouteGuide}
      isRouteGuideDetails={isRouteGuideDetails}
    >
      <div className="No__Route__Main">
        <div className="No__Route__row">
          <div className="No__Route__message">{message}</div>
        </div>
      </div>
    </StyledNoRouteFound>
  );
};

NoRouteFound.defaultProps = {
  isRoutesList: false,
  isRouteTrack: false,
  isRouteGuide: false,
  message: 'No data found.'
};
