import React from 'react';
import styled from 'styled-components';

export const StyledNewUpdateAlert = styled.div`
  color: #454545;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 5px 10px 5px;
  .row {
    display: flex;
    justify-content: space-between;
  }
  .row-1 {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
  }
  .row-2 {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  .alerts {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
  }
  .time {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }
  .message {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const RealodButton = styled.button`
  border: none;
  box-shadow: none;
  background: none;
  outline: none;
  width: 120px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #00acee;
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  padding: 10px 15px 10px;
  border-radius: 25px;
  text-transform: capitalize;
  cursor: pointer;
`;

export const NewUpdateAlert: React.FC = () => {
  return (
    <StyledNewUpdateAlert>
      <div className="row">
        <div className="alerts">Update : New app update is available!</div>
      </div>
      <div className="row-1">
        <div className="message">
          Please click reload button to refresh the page.
        </div>
      </div>
      <div className="row-2">
        <RealodButton onClick={() => window.location.reload()}>
          Reload
        </RealodButton>
      </div>
    </StyledNewUpdateAlert>
  );
};
