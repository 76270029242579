import React, { useState } from 'react';
import { StyledSubHeader } from 'styled/SubHeader';
import { getDisplayDateTime } from 'common/utils';
import { useInterval } from 'react-use';

type IProps = {
  isShowNote?: boolean;
};

export const SubHeader: React.FC<IProps> = (props: IProps) => {
  const { isShowNote } = props;
  const [state, setState] = useState(() => {
    return getDisplayDateTime();
  });
  const { time, date } = state;

  useInterval(() => {
    setState(() => {
      return getDisplayDateTime();
    });
  }, 60000);

  return (
    <StyledSubHeader>
      <div className="SubHeader__Main">
        <div className="SubHeader__row">
          <div className="SubHeader__column">
            <span className="icoview time"></span>
            <span className="text">{time}</span>
          </div>
          <div className="SubHeader__column">
            <span className="icoview date"></span>
            <span className="text">{date}</span>
          </div>
        </div>
        {isShowNote && (
          <div className="SubHeader__subtext__row">
            Please select a route to see the progress of the bus.
          </div>
        )}
      </div>
    </StyledSubHeader>
  );
};

SubHeader.defaultProps = {
  isShowNote: false
};
