import styled from 'styled-components';
import { images } from 'config/images';

export const StyledHSQHomeScreenPopup = {
  position: 'fixed',
  width: '100%',
  left: '0',
  right: '0',
  maxWidth: '100%',
  margin: '0 auto',
  bottom: '0',
  padding: '15px',
  background: 'transparent',
  border: 'none'
} as React.CSSProperties;

export type IStyledHSQHomeScreenProps = {
  isIos?: boolean;
};

export const StyledHSQHomeScreen = styled.div<IStyledHSQHomeScreenProps>`
  .HSQHomeScreen__Main {
    /* padding: 20px 0; */
    .HSQHomeScreen__row {
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      padding: 0;
      z-index: 1;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .HSQHomeScreen__close__icon {
    position: absolute;
    right: 10px;
    top: 10px;
    background-size: 16px;
    background: url('${images.iconclose}') no-repeat center center;
    color: #fff;
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  }

  .HSQHomeScreen__column__full {
    display: flex;
    align-items: center;
    padding: ${({ isIos }) => (isIos ? '40px 22px 15px' : '40px 22px 30px')};

    width: 100%;
    .HSQHomeScreen__Busicon {
      background: #00acee url('${images.iconbus}') no-repeat center center;
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      line-height: 22px;
      color: #fff;
      border-radius: 50%;
    }
    .HSQHomeScreen__title {
      color: #454545;
      font-size: 26px;
      line-height: 30px;
      padding-left: 18px;
    }
  }
  .HSQHomeScreen__column__info_ios{
    padding: 0 30px 30px;
    ul {
     list-style:none;
     li {
      font-size: 16px;
      line-height: 18px;
      color: #777777;
      padding : 5px 0px 5px 0px;
     }
    }
  }
  .HSQHomeScreen__column__btn__full {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1f8eed;
    color: #fff;
    font-size: 16px;
    line-height: 19px;
    padding: 12px;
    border-radius: 0;
    cursor: pointer;
    width: 100%;
  }
`;
