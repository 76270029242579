import React from 'react';
import { history } from 'config/routes';
import { routes } from 'config/routes_config';
import { images } from 'config/images';
import { StyledHeader } from 'styled/Header';

type IProps = {
  isShowHomeIcon?: boolean;
};

export const Header: React.FC<IProps> = (props: IProps) => {
  const { isShowHomeIcon } = props;

  const gotoHome = () => {
    history.push(routes.index.path);
  };

  return (
    <StyledHeader isShowHomeIcon={isShowHomeIcon}>
      <div className="Header__Main">
        <div className="header__center__column">
          <div className="logo">
            <img src={images.logo} alt="logo" />
          </div>
          <span className="header__title">HSQ Bus</span>
        </div>
        {isShowHomeIcon && (
          <div className="header__iconhome" onClick={gotoHome}>
            <img src={images.iconhome} alt="iconhome" />
          </div>
        )}
      </div>
    </StyledHeader>
  );
};

Header.defaultProps = {
  isShowHomeIcon: false
};
