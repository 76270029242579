import React from 'react';
import { history } from 'config/routes';
import { routes as routes_config } from 'config/routes_config';
import { RoutesListContainer } from 'containers/RoutesListContainer';
import { GeneralAlertsContainer } from 'containers/GeneralAlertsContainer';
import { Header } from 'components/Header';
import { SubHeader } from 'components/SubHeader';
import { Button } from 'components/Button';
import { StyledRouteListPage, StyledRouteGuide } from 'styled/RouteListPage';

export const RouteListPage: React.FC = () => {
  const onRouteGuide = () => {
    history.push(routes_config.routes_guide_list.path);
  };

  return (
    <StyledRouteListPage>
      <Header />
      <div className="content-area">
        <SubHeader isShowNote={true} />
        <RoutesListContainer />
        <GeneralAlertsContainer />
        <StyledRouteGuide>
          <div className="btn__route__guide">
            <Button onClick={onRouteGuide}>Route Guide</Button>
          </div>
        </StyledRouteGuide>
      </div>
    </StyledRouteListPage>
  );
};
