import React, { useMemo } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { StyledRouteCardSkeleton } from 'styled/RouteCardSkeleton';

type IProps = {
  count?: number;
  isRouteTrack?: boolean;
  isRouteGuide?: boolean;
  isRouteGuideDetails?: boolean;
};

export const RouteCardSkeleton: React.FC<IProps> = (props: IProps) => {
  const { count, isRouteTrack, isRouteGuide, isRouteGuideDetails } = props;

  const renderSkeletons = useMemo(() => {
    const skeletons: any = [];
    //@ts-ignore
    for (let index = 0; index < count; index++) {
      skeletons.push(
        <SkeletonTheme color="#eee" highlightColor="white" key={index}>
          <StyledRouteCardSkeleton
            isRouteTrack={isRouteTrack}
            isRouteGuide={isRouteGuide}
            isRouteGuideDetails={isRouteGuideDetails}
          >
            <div className="RouteCardSkeleton__Main">
              <div className="RouteCardSkeleton__row">
                <div className="RouteCardSkeleton__Column__left">
                  <Skeleton circle={true} height={50} width={50} />
                </div>
                <div className="RouteCardSkeleton__Column__right">
                  <Skeleton height={20} width={170} />
                  {!isRouteTrack && (
                    <div className="route_skeleton__note">
                      <Skeleton height={13} width={170} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </StyledRouteCardSkeleton>
        </SkeletonTheme>
      );
    }

    return skeletons;
  }, [count, isRouteTrack, isRouteGuide, isRouteGuideDetails]);

  return renderSkeletons;
};

RouteCardSkeleton.defaultProps = {
  count: 1,
  isRouteTrack: false,
  isRouteGuide: false,
  isRouteGuideDetails: false
};
