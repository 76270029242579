import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
*
{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background:#F9FAFC;
  overflow:hidden;
}

a {
  text-decoration: none;
}

#root {
  min-height:100vh;
}

.loading__block {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.Toastify__toast-container.Toastify__toast-container--top-center{
    padding: 20px;
    width: 100%;
    left: 0;
    margin-left:0;
}
.real-time-alert-toast {
  margin-bottom : 0 !important;
  border-radius: 6px !important;
  background: rgba(255, 255, 255, 0.9) !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16) !important;
  border-radius: 6px !important;
}
`;
