import { get } from 'services';
import { AxiosPromise } from 'axios';
import { IRoute, IGuideRoute, IAlert } from 'types/index';

export type IGetRoutesResponse = {
  routes: IRoute[];
  total: number;
};

export const getRoutesApi = (): AxiosPromise<IGetRoutesResponse> => {
  return get({ subUrl: 'rider/route_runs' });
};

export type IGetRouteByIdParams = {
  route_id: number;
};

export type IGetRoutesByIdResponse = {
  routes: IRoute[];
  total: number;
};

export const getRouteByIdApi = (
  params: IGetRouteByIdParams
): AxiosPromise<IGetRoutesByIdResponse> => {
  const { route_id } = params;
  return get({ subUrl: 'rider/route_runs', params: { route_id } });
};

export type IGetAlertsResponse = {
  alerts: IAlert[];
  total: number;
};

export const getAlertsApi = (): AxiosPromise<IGetAlertsResponse> => {
  return get({ subUrl: 'rider/get_alerts' });
};

export type IGetGuideRoutesResponse = {
  routes: IGuideRoute[];
  total: number;
};

export const getGuideRoutesApi = (): AxiosPromise<IGetGuideRoutesResponse> => {
  return get({ subUrl: 'rider/route_maps' });
};

export type IGetGuideRouteByIdParams = {
  route_id: number;
};

export type IGetGuideRoutesByIdResponse = {
  routes: IGuideRoute[];
  total: number;
};

export const getGuideRouteByIdApi = (
  params: IGetGuideRouteByIdParams
): AxiosPromise<IGetGuideRoutesByIdResponse> => {
  const { route_id } = params;
  return get({ subUrl: 'rider/route_maps', params: { route_id } });
};
