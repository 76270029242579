import React, { useState } from 'react';
import classNames from 'classnames';
import { IRun } from 'types/index';
import { StyledMapRouteRunCard } from 'styled/MapRouteRunCard';
import { getDisplayDateTime, constantValue } from 'common/utils';

type IProps = {
  selectedRouteRun: IRun | any;
  runs: IRun[];
  onChangeRouteRun?: (run: IRun) => void;
};

export const MapRouteCard: React.FC<IProps> = (props: Partial<IProps>) => {
  const { selectedRouteRun, runs, onChangeRouteRun } = props;
  const { run_id: selectedRunId } = selectedRouteRun;
  const timeHMFormat: string = 'HMM';
  let current_route = 0;
  const [dateTime, setDateTime] = useState(() => {
    return getDisplayDateTime();
  });
  const onChangeRun = (run: IRun) => {
    onChangeRouteRun && onChangeRouteRun(run);
  };

  return (
    <StyledMapRouteRunCard>
      <div className="MapRouterunCard__Main">
        {/* {runs?.slice(0, 2).map(run => { */}
        {runs?.map((run, index) => {
          const { run_id, run_route_map_name, run_scheduled_time } = run;
          let scheduledTime = run_scheduled_time.slice(0, 2);
          let secheduleTimeH = parseInt(scheduledTime) + 1;
          let currentTimeH = parseInt(dateTime.timeH);
          current_route = secheduleTimeH > currentTimeH ? current_route + 1 : 0;

          return (
            // secheduleTimeH > currentTimeH &&
            // 2 >= current_route &&
            <div
              className={classNames('MapRouterunCard__row', {
                'selected-route-run': run_id === selectedRunId
              })}
              key={run_id}
              onClick={() => run_id !== selectedRunId && onChangeRun(run)}
            >
              <div className="MapRouterunCard__time__col">
                {/* {run_scheduled_time
                  ? nextDepartureTime(run_scheduled_time)
                  : ''}{' '}

                run */}
                Bus {`${index + 1}`}
              </div>
              <div className="MapRouterunCard__route__col">
                Route:{' '}
                <span>{run_route_map_name ? run_route_map_name : '-'}</span>
              </div>
            </div>
          );
        })}
      </div>
    </StyledMapRouteRunCard>
  );
};

MapRouteCard.defaultProps = {
  selectedRouteRun: {},
  runs: [],
  onChangeRouteRun: () => {}
};
