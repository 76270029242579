import styled from 'styled-components';

export type IStyledHeaderProps = {
  isShowHomeIcon?: boolean;
};

export const StyledHeader = styled.div<IStyledHeaderProps>`
  .Header__Main {
    background: #00acee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 20px;
    padding: ${({ isShowHomeIcon }) =>
      isShowHomeIcon ? '12.5px 20px' : '19px 20px'};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    .header__center__column {
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      justify-content: center;
      margin-left: ${({ isShowHomeIcon }) => (isShowHomeIcon ? '36px' : '0')};
      .logo {
        display: flex;
        width: 93px;
      }
      .header__title {
        font-size: 20px;
        line-height: 23px;
        color: #fff;
        font-weight: 900;
        padding-left: 8px;
      }
    }
    .header__iconhome {
      background: #0d7ad7;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      border-radius: 20px;
      height: 36px;
      width: 36px;
      max-width: 36px;
      max-height: 36px;
      flex: 0 0 36px;
      cursor: pointer;
    }
  }
`;
