import styled from 'styled-components';

export const GeneralAlertCardSkeleton = styled.div`
  .GeneralAlertCardSkeleton__Main {
    position: relative;
    .GeneralAlertCardSkeleton__row {
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      padding: 16px 22px 25px 22px;
      display: flex;
      align-items: flex-start;
      z-index: 1;
      justify-content: space-between;
      overflow: hidden;
    }
  }
  .GeneralAlertCardSkeleton__Column__left {
    padding-right: 10px;
    flex: 1 1 auto;
    max-width: 70%;
    .GeneralAlertCardSkeleton__title {
      padding-bottom: 6px;
    }
  }
`;
