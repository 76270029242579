import Pusher from 'pusher-js';
import {
  PUSHER_APP_KEY,
  PUSHER_APP_CLUSTER,
  LIVE_TRACK_PUSHER_APP_KEY,
  LIVE_TRACK_PUSHER_APP_CLUSTER
} from 'config/env';
import {
  IAlert,
  IRouteRunTrackingParams,
  IRouteRunTrackingResponse
} from 'types';

export const pusher = new Pusher(PUSHER_APP_KEY, {
  cluster: PUSHER_APP_CLUSTER,
  forceTLS: true
});

export const pusherLiveTrack = new Pusher(LIVE_TRACK_PUSHER_APP_KEY, {
  cluster: LIVE_TRACK_PUSHER_APP_CLUSTER,
  forceTLS: true
});

export const config = {
  witb_service_channel: 'witb-service-channel',
  witb_service_channel_rider: 'witb-service-channel-rider',
  witb_service_alert: 'witb-service-alert',
  witb_service_live_location: 'witb-service-live-location'
};

export const subscribeForAlerts = (callback: (data: IAlert) => void) => {
  const channel = pusher.subscribe(config.witb_service_channel_rider);

  channel.bind(config.witb_service_alert, data => {
    callback(data);
  });
};

type ILiveLocationSubsParams = {
  callback: (data: IRouteRunTrackingResponse) => void;
  data: IRouteRunTrackingParams;
};

export const subscribeForLiveLocation = (params: ILiveLocationSubsParams) => {
  const {
    callback,
    data: { route_id, run_id }
  } = params;
  const channel = pusherLiveTrack.subscribe(
    `${config.witb_service_channel}-${route_id}-${run_id}`
  );
  console.log(
    `Subscribe pusher for live track location :: ${config.witb_service_channel}-${route_id}-${run_id}`
  );

  channel.bind(config.witb_service_live_location, data => {
    console.log('Received pusher live track location :: ', data);
    callback(data);
  });
};

export const unsubscribeForLiveLocation = (channel: string) => {
  pusherLiveTrack.unsubscribe(channel);
};
