import React, { useContext, Fragment } from 'react';
import { observer } from 'mobx-react';
import { rootContext } from 'stores';
import { useMount } from 'react-use';
import { useParams } from 'react-router-dom';
import { RouteCard } from 'components/RouteCard';
import { NoRouteFound } from 'components/NoRouteFound';
import { RouteCardSkeleton } from 'components/RouteCardSkeleton';
import { NoRunsFound } from 'components/NoRunsFound';
import { Map } from 'components/Map';
import { DeparturesList } from 'components/DeparturesList';
import { StyledRouteGuideDetailsContainer } from 'styled/RouteGuideDetailsContainer';
import { StyledGoogleMap } from 'styled/GoogleMap';

export const RouteGuideDetailsContainer: React.FC = observer(() => {
  const { route } = useParams();
  const {
    RouteGuideDetailsStore: { state, onGetRouteGuideDetails }
  } = useContext(rootContext);
  const {
    ui: { isRouteGuideDetailsLoading },
    data: { routeGuideDetails, selectedGuideRun, guideDetailsErrorMessage }
  } = state;

  useMount(() => {
    onGetRouteGuideDetails({ route_id: Number(route) });
  });

  return (
    <StyledRouteGuideDetailsContainer>
      <div className="RouteGuideDetailsContainer__Main">
        {isRouteGuideDetailsLoading ? (
          <RouteCardSkeleton isRouteGuideDetails={true} />
        ) : routeGuideDetails && Object.keys(routeGuideDetails).length ? (
          <Fragment>
            <RouteCard
              cardDetails={routeGuideDetails}
              isRouteGuideDetails={true}
            />
            {routeGuideDetails?.maps?.length ? (
              <Map selectedRouteRun={selectedGuideRun} isLiveTrack={false} />
            ) : (
              <StyledGoogleMap>
                <NoRunsFound />
              </StyledGoogleMap>
            )}
            <div className="RouteGuideDetailsContainer__DeparturesList">
              <DeparturesList route_days={routeGuideDetails.route_days} />
            </div>
          </Fragment>
        ) : (
          <NoRouteFound
            isRouteGuideDetails={true}
            message={guideDetailsErrorMessage}
          />
        )}
      </div>
    </StyledRouteGuideDetailsContainer>
  );
});
