import dayjs from 'dayjs';
import { IRouteMapConverted, IRouteDepartureDays } from 'types/index';

const dateFormat: string = 'dddd, MMMM D';
const timeFormat: string = 'h:mm a';
const timeHFormat: string = 'H';
const timeHMFormat: string = 'HMM';
const tempDepartureDate: string = '1970-01-01';

export const departuresDayKeys: string[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

export const getDisplayDateTime = () => {
  const todaysDate = dayjs();
  const timeHM = todaysDate.format(timeHMFormat);
  const timeH = todaysDate.format(timeHFormat);
  const time = todaysDate.format(timeFormat);
  const date = todaysDate.format(dateFormat);
  return {
    timeHM,
    timeH,
    date,
    time
  };
};

export const nextDepartureTime = (
  departureTime: string,
  departureDate?: string
) => {
  // Append any date for convert the time-of-day string to format with AM/PM
  return dayjs(
    `${departureDate ? departureDate : tempDepartureDate}${departureTime}`
  ).format(timeFormat);
};

export const generalAlertTime = (date: string) => {
  return dayjs(date).format(timeFormat);
};

export const arrayOfArraysIntoArrayOfObjects = (data: IRouteMapConverted) => {
  return data.map(([lng, lat]) => ({ lng, lat }));
};

export const getRouteDays = (routeDays: IRouteDepartureDays) => {
  const keys = Object.keys(routeDays);
  const days = keys
    .map((index: any) => departuresDayKeys[index - 1].substring(0, 3))
    .join(', ');

  return days;
};

export const getDepartureDay = (key: string) => {
  return departuresDayKeys[parseInt(key) - 1];
};

export const constantValue = {
  stopRouteTime: 16,
  startRouteTime: 6
};
