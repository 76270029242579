import styled from 'styled-components';

export const StyledGeneralAlertCard = styled.div`
  .GeneralAlertCard__Main {
    position: relative;
    .GeneralAlertCard__row {
      background: #00acee;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      padding: 16px 22px 25px 22px;
      z-index: 1;
      overflow: hidden;
    }
  }

  .GeneralAlertCard__Column__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .GeneralAlertCard__Column__center {
    padding: 0px;
    text-align: center;
    max-width: 100%;
    .GeneralAlertCard__title {
      color: #454545;
      font-size: 20px;
      line-height: 23px;
      padding-bottom: 6px;
      text-transform: capitalize;
    }
  }

  .GeneralAlertCard__no_alert_msg {
    font-size: 14px;
    line-height: 16px;
    color: #7b7b7b;
    font-style: italic;
    padding-bottom: 4px;
    font-weight: 500;
  }
  .GeneralAlertCard__msg {
    font-size: 14px;
    line-height: 16px;
    color: #454545;
    white-space: nowrap;
    max-width: 95%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .GeneralAlertCard__Column__right {
    flex: 0 0 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .GeneralAlertCard__time {
      font-size: 16px;
      line-height: 20px;
      color: #454545;
    }
  }
  .GeneralAlertCard__showall__row {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 15px;
    top: 99%;
    .GeneralAlertCard__showall__btn {
      background: #454545;
      color: #fff;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 4px 12px 8px;
      border-radius: 10px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      max-width: 82px;
      text-transform: capitalize;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
  }
  @media screen and (max-width: 360px) {
    .GeneralAlertCard__Column__right {
      .GeneralAlertCard__time {
        padding-right: 26px;
      }
    }
  }
`;
