import { observable, action } from 'mobx';
import { getRouteByIdApi } from 'services/rider';
import {
  config,
  subscribeForLiveLocation,
  unsubscribeForLiveLocation
} from 'services/pusher';
import { IRoute, IRun, IRouteRunTrackingResponse } from 'types/index';
import { RootStore } from 'stores';
import { getApiErrorMessage } from 'services/utils';

export type IInitialState = {
  data: {
    routeDetails: IRoute | null;
    selectedRouteRun: IRun | null;
    routeDetailsErrorMessage: string | null;
  };
  ui: {
    isRouteDetailsLoading: boolean;
  };
};

export const initialState: IInitialState = {
  data: {
    routeDetails: null,
    selectedRouteRun: null,
    routeDetailsErrorMessage: null
  },
  ui: {
    isRouteDetailsLoading: false
  }
};

export class RouteDetailsStore {
  @observable state: IInitialState = initialState;
  rootStore: RootStore;
  subscribedChannel: any = new Map();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    /* use this space to hydrate state from localStorage */
  }

  onGetRouteDetails = async ({ route_id }: { route_id: number }) => {
    const { data } = this.state;
    this.setState({
      ui: { isRouteDetailsLoading: true }
    });
    try {
      const response = await getRouteByIdApi({ route_id });
      const selectedRouteRun = response?.data?.routes[0]?.runs[0] || null;
      this.setState({
        data: {
          routeDetails: response?.data?.routes[0] || null,
          selectedRouteRun,
          routeDetailsErrorMessage: null
        },
        ui: {
          isRouteDetailsLoading: false
        }
      });
      if (selectedRouteRun) {
        this.onSubscribeForLiveLocation(selectedRouteRun);
      }
    } catch (error) {
      const message = getApiErrorMessage(error);
      this.setState({
        data: {
          ...data,
          routeDetailsErrorMessage: message
        },
        ui: {
          isRouteDetailsLoading: false
        }
      });
    }
  };

  onChangeRouteRun = (run: IRun) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        selectedRouteRun: run
      }
    });
    this.onSubscribeForLiveLocation(run);
  };

  onSubscribeForLiveLocation = (run: IRun) => {
    const { run_route_id, run_id } = run;
    const channel = `${config.witb_service_channel}-${run_route_id}-${run_id}`;
    this.unSubscribePusherForLiveLocation();
    this.subscribedChannel.set(channel, channel);

    subscribeForLiveLocation({
      callback: (data: IRouteRunTrackingResponse) => {
        const { run_lat, run_lng } = data;
        const { data: state } = this.state;
        const routeRun = {
          ...run,
          run_lat: Number(run_lat),
          run_lng: Number(run_lng)
        };
        this.setState({
          data: {
            ...state,
            selectedRouteRun: {
              ...routeRun
            }
          }
        });
      },
      data: { route_id: run_route_id, run_id }
    });
  };

  unSubscribePusherForLiveLocation = () => {
    if (this.subscribedChannel.size) {
      for (const key of this.subscribedChannel.keys()) {
        unsubscribeForLiveLocation(key);
      }
      this.subscribedChannel.clear();
    }
  };

  @action
  setState = (params: Partial<IInitialState>) => {
    const { state } = this;
    this.state = {
      ...state,
      ...params
    };
    /* use this space to save/sync state with localStorage */
  };

  @action
  reset = () => {
    this.state = initialState;
    /* use this space to reset localStorage in case if you're persisting this state */
  };
}
