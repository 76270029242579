import { observable, action } from 'mobx';
import { getRoutesApi } from 'services/rider';
import { RootStore } from 'stores';
import { history } from 'config/routes';
import { IRoute } from 'types/index';
import { routes as routes_config } from 'config/routes_config';

export type IInitialState = {
  data: {
    routesList: IRoute[] | null;
    totalRoutes: number | null;
  };
  ui: {
    isRoutesListLoading: boolean;
  };
};

export const initialState: IInitialState = {
  data: {
    routesList: null,
    totalRoutes: null
  },
  ui: {
    isRoutesListLoading: false
  }
};

export class RouteStore {
  @observable state: IInitialState = initialState;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    /* use this space to hydrate state from localStorage */
  }

  onGetRoutesList = async () => {
    this.setState({
      ui: { isRoutesListLoading: true }
    });
    try {
      const response = await getRoutesApi();
      this.setState({
        data: {
          routesList: response?.data?.routes || null,
          totalRoutes: response?.data?.total || null
        },
        ui: {
          isRoutesListLoading: false
        }
      });
    } catch (error) {
      this.setState({
        ui: {
          isRoutesListLoading: false
        }
      });
    }
  };

  onRouteSelect = ({ route_id }: { route_id: number }) => {
    history.push(routes_config.route_tracker.path_string({ route_id }));
  };

  @action
  setState = (params: Partial<IInitialState>) => {
    const { state } = this;
    this.state = {
      ...state,
      ...params
    };
    /* use this space to save/sync state with localStorage */
  };

  @action
  reset = () => {
    this.state = initialState;
    /* use this space to reset localStorage in case if you're persisting this state */
  };
}
