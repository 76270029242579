import styled from 'styled-components';

export type IStyledButtonProps = {
  primary?: boolean;
};

export const StyledButton = styled.button<IStyledButtonProps>`
  border: none;
  box-shadow: none;
  background: none;
  outline: none;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  .Button__Main {
    .Button__block {
      background: #00acee;
      color: #fff;
      font-size: 20px;
      line-height: 22px;
      padding: 12px 20px 14px;
      border-radius: 25px;
      text-transform: capitalize;
      width: 100%;
      cursor: pointer;
    }
  }
`;
