import React from 'react';
import styled from 'styled-components';
import { images } from 'config/images';
import { AiFillCloseCircle } from 'react-icons/ai';

export const StyledCloseButton = styled.div`
  background: url('${images.iconclose}') no-repeat center center;
  height: 16px;
  width: 16px;
`;

export const CloseButton: React.FC = () => {
  return <AiFillCloseCircle color="black" style={{ height: 25, width: 25 }} />;
};
