import styled from 'styled-components';

export type IStyledRouteCardProps = {
  isRoutesList?: boolean;
  isRouteTrack?: boolean;
  isRouteGuide?: boolean;
  isRouteGuideDetails?: boolean;
};

export const StyledNoRouteFound = styled.div<IStyledRouteCardProps>`
  .No__Route__Main {
    padding: 0 19px;
  }
  .No__Route__row {
    background: #ffffff;
    border-radius: 6px;
    padding: ${({
      isRoutesList,
      isRouteTrack,
      isRouteGuide,
      isRouteGuideDetails
    }) =>
      isRoutesList
        ? '30px 15px 29px 20px'
        : isRouteTrack
        ? '22px 15px 21px 20px'
        : isRouteGuide
        ? '30px 15px 29px 20px'
        : isRouteGuideDetails
        ? '22px 15px 21px 20px'
        : '22px 15px 21px 20px'};

    display: flex;
    align-items: center;
    border-radius: 6px 0px 0px 6px;
    border-left: 9px solid #7b7b7b;
  }
  .No__Route__message {
    font-size: 20px;
    line-height: 24px;
    color: #7b7b7b;
    font-style: italic;
    padding-bottom: 4px;
    font-weight: 500;
  }
`;
