import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

export type IRoutesConfig = {
  [key: string]: {
    id: string;
    name: string;
    description?: string;
    path: string;
    path_string: (params: any) => string;
    exact: boolean;
    isPrivate: boolean;
    component?:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>;
  };
};
/**
 * @description the aim to create this config is to have
 *  a single source of truth for the routes defination.
 *  the reason we are not importing the components here
 *  for the property `component` is to avoid circular
 *  import dependencies error.
 *  components will be assigned in config/routes.ts
 */
export const routes: IRoutesConfig = {
  index: {
    id: 'index',
    name: 'Routes',
    description: 'Routes Listing',
    path: '/',
    path_string: () => {
      return `/`;
    },
    exact: true,
    isPrivate: false,
    component: undefined
  },
  route_tracker: {
    id: 'route_tracker',
    name: 'Route Tracking',
    description: 'Track Routes',
    path: '/track/:route',
    path_string: (params: { route_id: number }) => {
      const { route_id } = params;
      return `/track/${route_id}`;
    },
    exact: true,
    isPrivate: false,
    component: undefined
  },
  routes_guide_list: {
    id: 'routes_guide_list',
    name: 'Routes Guide List',
    path: '/guide',
    path_string: () => {
      return `/guide`;
    },
    exact: true,
    isPrivate: false,
    component: undefined
  },
  route_guide_details: {
    id: 'route_guide_details',
    name: 'Route Guide Details',
    path: '/guide/:route',
    path_string: (params: { route_id: number }) => {
      const { route_id } = params;
      return `/guide/${route_id}`;
    },
    exact: true,
    isPrivate: false,
    component: undefined
  }
};
