import { createContext } from 'react';
import { observable } from 'mobx';
import remotedev from 'mobx-remotedev';
import { AppStore } from './app';
import { RouteStore } from './routes';
import { AlertsStore } from './alerts';
import { RouteDetailsStore } from './route_details';
import { RouteGuideStore } from './route_guide';
import { RouteGuideDetailsStore } from './route_guide_details';

export class RootStore {
  AppStore: AppStore;
  RouteStore: RouteStore;
  AlertsStore: AlertsStore;
  RouteDetailsStore: RouteDetailsStore;
  RouteGuideStore: RouteGuideStore;
  RouteGuideDetailsStore: RouteGuideDetailsStore;

  @observable version = 1;

  constructor() {
    this.AppStore = new AppStore(this);
    this.RouteStore = new RouteStore(this);
    this.AlertsStore = new AlertsStore(this);
    this.RouteDetailsStore = new RouteDetailsStore(this);
    this.RouteGuideStore = new RouteGuideStore(this);
    this.RouteGuideDetailsStore = new RouteGuideDetailsStore(this);
  }
}

export const rootStore = (() => {
  const store = remotedev(RootStore, {
    global: true,
    onlyActions: true,
    name: 'WTB'
  });
  return new store();
})();

export const rootContext = createContext(rootStore);
