import React from 'react';
import { RouteTrackerContainer } from 'containers/RouteTrackerContainer';
import { Header } from 'components/Header';
import { SubHeader } from 'components/SubHeader';
import { StyledRouteTrackerPage } from 'styled/RouteTrackerPage';

export const RouteTrackerPage: React.FC = () => {
  return (
    <StyledRouteTrackerPage>
      <Header isShowHomeIcon={true} />
      <div className="content-area">
        <SubHeader />
        <RouteTrackerContainer />
      </div>
    </StyledRouteTrackerPage>
  );
};
