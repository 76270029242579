import styled from 'styled-components';
import { images } from 'config/images';

export const StyledMapNotificationAlert = styled.div`
  bottom: 15px;
  position: fixed;
  width: 100%;
  .MapNavigationCard__Main {
    padding:0 19px;
  }
  .MapNavigationCard__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 40px;
    padding:11px 13px;
    .MapNavigationCard__column__main{
      display: flex;
      overflow: hidden;
      position: relative;
      width: 100%;
      margin-left: 5px;
      .MapNavigationCard__column__main__row{
        display: flex;
        animation: marquee 40s linear infinite;
        p {
          font-size: 16px;
          line-height: 19px;
          color: #000000;
          flex:1 1 auto;
          white-space: nowrap;
          padding: 0 10px 0 10px;
        }
      }   
      .MapNavigationCard__column__main__row:hover {
        animation-play-state: paused
      }   
    }
    .MapNavigationCard__nextarrow {
      background: url('${images.arrownext}') no-repeat center center;
      height: 17px;
      width: 17px;
      flex: 0 0 17px;
      overflow: hidden;
      display: flex;
      align-items: center;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
  }
  @keyframes marquee {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(-100%, 0);
      }
  }

  @media screen and (max-width: 400px) {
    .MapNavigationCard__row {
      .MapNavigationCard__column__main{
        .MapNavigationCard__column__main__row{
          p {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
      .MapNavigationCard__nextarrow {
        height: 15px;
        width: 15px;
      }
    }
  }
  @media screen and (max-width: 399px) {
    .MapNavigationCard__row {
      .MapNavigationCard__column__main{
        .MapNavigationCard__column__main__row{
          p {
            font-size: 13px;
            line-height: 16px;
          }
        }
      }
      .MapNavigationCard__nextarrow {
        height: 13px;
        width: 13px;
      }
    }
  }
`;
