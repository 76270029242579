import { observable, action } from 'mobx';
import { getGuideRouteByIdApi } from 'services/rider';
import { IGuideRoute, IGuideRun } from 'types/index';
import { RootStore } from 'stores';
import { getApiErrorMessage } from 'services/utils';

export type IInitialState = {
  data: {
    routeGuideDetails: IGuideRoute | null;
    selectedGuideRun: IGuideRun | null;
    guideDetailsErrorMessage: string | null;
  };
  ui: {
    isRouteGuideDetailsLoading: boolean;
  };
};

export const initialState: IInitialState = {
  data: {
    routeGuideDetails: null,
    selectedGuideRun: null,
    guideDetailsErrorMessage: null
  },
  ui: {
    isRouteGuideDetailsLoading: false
  }
};

export class RouteGuideDetailsStore {
  @observable state: IInitialState = initialState;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    /* use this space to hydrate state from localStorage */
  }

  onGetRouteGuideDetails = async ({ route_id }: { route_id: number }) => {
    const { data } = this.state;
    this.setState({
      ui: { isRouteGuideDetailsLoading: true }
    });
    try {
      const response = await getGuideRouteByIdApi({ route_id });
      const routeMaps = response?.data?.routes[0]?.maps;
      const selectedRunMap =
        routeMaps?.find(map => map.route_map_standard) || routeMaps[0] || null;
      this.setState({
        data: {
          routeGuideDetails: response?.data?.routes[0] || null,
          selectedGuideRun: selectedRunMap || null,
          guideDetailsErrorMessage: null
        },
        ui: {
          isRouteGuideDetailsLoading: false
        }
      });
    } catch (error) {
      const message = getApiErrorMessage(error);
      this.setState({
        data: {
          ...data,
          guideDetailsErrorMessage: message
        },
        ui: {
          isRouteGuideDetailsLoading: false
        }
      });
    }
  };

  @action
  setState = (params: Partial<IInitialState>) => {
    const { state } = this;
    this.state = {
      ...state,
      ...params
    };
    /* use this space to save/sync state with localStorage */
  };

  @action
  reset = () => {
    this.state = initialState;
    /* use this space to reset localStorage in case if you're persisting this state */
  };
}
