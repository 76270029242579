import styled, { css } from 'styled-components';

export type IStyledRoutesGLProps = {
  isLoading?: boolean;
  isNoRoutes?: boolean;
};

export const StyledRouteGuideListContainer = styled.div<IStyledRoutesGLProps>`
  .overflow__hidden {
    overflow: hidden;
    .RouteGuideListContainer__block {
      overflow: hidden;
      overflow-y: auto;
      margin-right: 0;
      padding-right: 0;
    }
    .RouteGuideListContainer__block::-webkit-scrollbar {
      width: 0px;
    }
    .RouteGuideListContainer__block::-webkit-scrollbar-thumb {
      width: 0px;
    }
  }

  .RouteGuideListContainer__Main {
    height: calc(100vh - 130px);
    ${({ isLoading, isNoRoutes }) =>
      (isLoading || (!isNoRoutes && !isLoading)) &&
      css`
        mask-image: linear-gradient(
          to bottom,
          transparent 0%,
          rgb(255, 255, 255) 0%,
          rgb(255, 255, 255) 80%,
          transparent 100%
        );
        mask-size: cover;
        -webkit-mask-image: linear-gradient(
          to bottom,
          transparent 0%,
          rgb(255, 255, 255) 0%,
          rgb(255, 255, 255) 80%,
          transparent 100%
        );
        -webkit-mask-size: cover;
      `}
    .overflow__hidden {
      overflow: hidden;
      .RouteGuideListContainer__block {
        max-height: calc(100vh - 130px);
      }
      .RouteGuideListContainer__block::-webkit-scrollbar {
        width: 0px;
      }
      .RouteGuideListContainer__block::-webkit-scrollbar-thumb {
        width: 0px;
      }
    }
  }
`;
