import React from 'react';
import { IAlert } from 'types/index';
import { generalAlertTime } from 'common/utils';
import { StyledMapNotificationAlert } from 'styled/MapNotificationAlert';

type IProps = {
  alertsList: IAlert[];
  onHandleShowAllAlerts: (value: boolean) => void;
};

export const MapNotificationAlert: React.FC<IProps> = (props: IProps) => {
  const { onHandleShowAllAlerts, alertsList } = props;
  const onOpen = () => {
    onHandleShowAllAlerts && onHandleShowAllAlerts(true);
  };

  return (
    <StyledMapNotificationAlert>
      <div className="MapNavigationCard__Main">
        <div className="MapNavigationCard__row">
          <div className="MapNavigationCard__column__main">
            <div className="MapNavigationCard__column__main__row">
              {alertsList.map(({ alert_id, alert_datetime, alert_message }) => (
                <p key={alert_id}>
                  {alert_datetime ? generalAlertTime(alert_datetime) : ''}
                  {'  '}
                  {alert_message ? alert_message : ''}
                </p>
              ))}
            </div>
          </div>
          <div className="MapNavigationCard__nextarrow" onClick={onOpen}></div>
        </div>
      </div>
    </StyledMapNotificationAlert>
  );
};

MapNotificationAlert.defaultProps = {
  alertsList: [],
  onHandleShowAllAlerts: () => {}
};
