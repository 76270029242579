import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { useMount } from 'react-use';
import { rootContext } from 'stores';
import Popup from 'reactjs-popup';
import {
  StyledHSQHomeScreen,
  StyledHSQHomeScreenPopup
} from 'styled/HSQHomeScreen';

export const HSQHomeScreen: React.FC = observer(() => {
  const [isIos, setIos] = useState(false);
  const {
    AppStore: {
      state: {
        data: { deferredPrompt },
        ui: { isAddToHomeScreen }
      },
      onHandleAddToHomeScreenPopUp,
      setPWADeferredPrompt
    }
  } = useContext(rootContext);

  useMount(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    // Detects if device is on iOS
    const isIOS = /ipad|iphone|ipod/.test(userAgent);
    const isMobile = /mobile/.test(userAgent);
    // Detects if device is in standalone mode
    const windowNavigator: any = window.navigator;
    const isInStandaloneMode =
      'standalone' in windowNavigator && windowNavigator.standalone;

    const beforeInstallListener = (e: any) => {
      // Prevent Chrome 76 and later from showing the install prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setPWADeferredPrompt(e);
      onHandleAddToHomeScreenPopUp(true);
    };

    // Checks if should display install popup notification:
    if (isIOS && !isInStandaloneMode) {
      setIos(true);
      onHandleAddToHomeScreenPopUp(true);
    } else if (isMobile && !isInStandaloneMode) {
      window.addEventListener('beforeinstallprompt', beforeInstallListener);
    }
  });

  const onClose = () => {
    onHandleAddToHomeScreenPopUp(false);
    setPWADeferredPrompt(null);
  };

  const onAddToHomeScreen = async () => {
    onHandleAddToHomeScreenPopUp(false);
    deferredPrompt.prompt(); // Wait for the user to respond to the prompt
    const choiceResult = await deferredPrompt.userChoice;
    if (choiceResult.outcome === 'accepted') {
      console.log('User accepted the PWA prompt');
    } else {
      console.log('User dismissed the PWA prompt');
    }
    setPWADeferredPrompt(null);
  };

  return (
    <StyledHSQHomeScreen isIos={isIos}>
      <Popup
        open={isAddToHomeScreen}
        modal
        closeOnDocumentClick={true}
        closeOnEscape={true}
        lockScroll={true}
        contentStyle={StyledHSQHomeScreenPopup}
      >
        <div className="HSQHomeScreen__Main">
          <div className="HSQHomeScreen__row">
            <div className="HSQHomeScreen__close__icon" onClick={onClose}></div>
            <div className="HSQHomeScreen__column__full">
              <div className="HSQHomeScreen__Busicon"></div>
              <div className="HSQHomeScreen__title">HSQ Bus</div>
            </div>
            {isIos ? (
              <div className="HSQHomeScreen__column__info_ios">
                <ul>
                  <li>1. Open Share menu</li>
                  <li>2. Tap on "Add to Home Screen" button</li>
                </ul>
              </div>
            ) : (
              <div
                className="HSQHomeScreen__column__btn__full"
                onClick={onAddToHomeScreen}
              >
                Add to My Apps
              </div>
            )}
          </div>
        </div>
      </Popup>
    </StyledHSQHomeScreen>
  );
});
