import styled from 'styled-components';

export const StyledRouteListPage = styled.div`
  .content-area {
    padding: 19px 0;
    position: relative;
    .btn__route__guide {
      position: fixed;
      width: 100%;
      left: 0;
      right: 0;
      max-width: 100%;
      margin: 0 auto;
      bottom: 0;
      background: #f9fafc;
      padding: 15px;
    }
  }
`;

export const StyledRouteGuide = styled.div``;
