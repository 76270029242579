import React, { useContext, Fragment } from 'react';
import { observer } from 'mobx-react';
import { rootContext } from 'stores';
import { useMount } from 'react-use';
import { GeneralAlertCard } from 'components/GeneralAlertCard';
import { GeneralAlertList } from 'components/GeneralAlertList';
import { GeneralAlertsSkeleton } from 'components/GeneralAlertsSkeleton';
import { StyledGeneralAlertsContainer } from 'styled/GeneralAlertsContainer';

export const GeneralAlertsContainer: React.FC = observer(() => {
  const {
    AlertsStore: { state, onGetAlertsList, onHandleShowAllAlerts }
  } = useContext(rootContext);
  const {
    ui: { isAlertsListLoading, isShowAllAlerts },
    data: { alertsList, alertsErrorMessage }
  } = state;

  useMount(() => {
    onGetAlertsList();
  });

  return (
    <StyledGeneralAlertsContainer>
      <div className="GeneralAlertsContainer__Main">
        {isAlertsListLoading ? (
          <GeneralAlertsSkeleton />
        ) : alertsList?.length ? (
          <Fragment>
            <GeneralAlertCard
              alertsList={alertsList}
              onHandleShowAllAlerts={onHandleShowAllAlerts}
            />
            <GeneralAlertList
              isShowAllAlerts={isShowAllAlerts}
              alertsList={alertsList}
              onHandleShowAllAlerts={onHandleShowAllAlerts}
            />
          </Fragment>
        ) : (
          <GeneralAlertCard
            isNoAlerts={true}
            alertsErrorMessage={alertsErrorMessage}
          />
        )}
      </div>
    </StyledGeneralAlertsContainer>
  );
});
