import React from 'react';
import { StyledButton, IStyledButtonProps } from 'styled/Button';

export type IButtonProps = IStyledButtonProps & {
  onClick?: () => void;
};

export const Button: React.FC<IButtonProps> = props => {
  return (
    <StyledButton {...props}>
      <div className="Button__Main">
        <div className="Button__block">{props.children}</div>
      </div>
    </StyledButton>
  );
};
