import React from 'react';
import { IAlert } from 'types/index';
import { generalAlertTime } from 'common/utils';
import { StyledGeneralAlertCard } from 'styled/GeneralAlertCard';

type IProps = {
  alertsList?: IAlert[];
  isNoAlerts?: boolean;
  onHandleShowAllAlerts?: (value: boolean) => void;
  alertsErrorMessage?: string | null;
};

export const GeneralAlertCard: React.FC<IProps> = (props: Partial<IProps>) => {
  const {
    isNoAlerts,
    alertsList = [],
    onHandleShowAllAlerts,
    alertsErrorMessage
  } = props;
  const [alert] = alertsList;

  const onShowAll = () => {
    onHandleShowAllAlerts && onHandleShowAllAlerts(true);
  };

  return (
    <StyledGeneralAlertCard>
      <div className="GeneralAlertCard__Main">
        <div className="GeneralAlertCard__row">
          <div className="GeneralAlertCard__Column__center">
            <div className="GeneralAlertCard__title">Rider Alerts</div>
          </div>
          <div className="GeneralAlertCard__Column__block">
            {isNoAlerts ? (
              <div className="GeneralAlertCard__no_alert_msg">
                {alertsErrorMessage}
              </div>
            ) : (
              <div className="GeneralAlertCard__msg">
                {alert.alert_message ? alert.alert_message : ''}
              </div>
            )}

            {!isNoAlerts && (
              <div className="GeneralAlertCard__Column__right">
                <div className="GeneralAlertCard__time">
                  {alert.alert_datetime
                    ? generalAlertTime(alert.alert_datetime)
                    : ''}
                </div>
              </div>
            )}
          </div>
        </div>
        {!isNoAlerts && (
          <div className="GeneralAlertCard__showall__row">
            <div className="GeneralAlertCard__showall__btn" onClick={onShowAll}>
              show all
            </div>
          </div>
        )}
      </div>
    </StyledGeneralAlertCard>
  );
};

GeneralAlertCard.defaultProps = {
  alertsList: [],
  isNoAlerts: false,
  onHandleShowAllAlerts: () => {},
  alertsErrorMessage: 'No Rider Alerts found'
};
