import styled from 'styled-components';
import { images } from 'config/images';

export const StyledGeneralAlertListPopup = {
  position: 'fixed',
  width: '100%',
  left: '0',
  right: '0',
  maxWidth: '100%',
  maxHeight: '100%',
  margin: '0 auto',
  bottom: '0',
  padding: '15px',
  background: 'transparent',
  border: 'none'
} as React.CSSProperties;

export const StyledGeneralAlertList = styled.div`
  .GeneralAlertList__Main {
    overflow: hidden;
    max-height: 100vh;
    margin-right: 0;
    padding-right: 0;
    .GeneralAlertList__row {
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      padding: 0;
      z-index: 1;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 14px;
    }
  }

  .GeneralAlertList__close__icon {
    position: absolute;
    right: 5px;
    top: 5px;
    background-size: 16px;
    background: url('${images.iconclose}') no-repeat center center;
    color: #fff;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  }

  .GeneralAlertList__title {
    font-size: 14px;
    line-height: 16px;
    color: #454545;
    font-weight: bold;
    width: 100%;
  }
  .GeneralAlertList__column__list__main{
    max-height: calc(100vh - 80px);
    overflow-y: auto;
    width: 100%;
    .GeneralAlertList__column__listfull {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 14px 0;
      width: 100%;
      border-bottom: 1px solid rgba(69, 69, 69, 0.5);
      &:last-child {
        border: none;
      }
      .GeneralAlertList__timetag {
        background: #c4c4c4;
        padding: 2px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        line-height: 12px;
        color: #454545;
        border-radius: 10px;
        margin-bottom: 8px;
        font-style: italic;
      }
      .GeneralAlertList__des {
        color: #454545;
        font-size: 14px;
        line-height: 16px;
        padding: 2px 0;
      }
    }
  }

  .GeneralAlertList__column__list__main::-webkit-scrollbar {
    width: 0px;
  }
  .GeneralAlertList__column__list__main::-webkit-scrollbar-thumb {
    width: 0px;
  }
`;
